import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import img1 from "../../../static/img/por-que/1.jpg"
import img2 from "../../../static/img/por-que/2.jpg"
import img3 from "../../../static/img/por-que/3.jpg"
import soluciones from "../../../static/img/por-que/buscamos_soluciones_en.png";

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Why IRStrat?"/>
            <Banner title="Why IRStrat?"/>

            <section className="pt-5 pb-5 porque">
                <div className="container">
                    <div className="section_title black_c ">
                        <h2 className="mt-4">Why IRStrat?</h2>
                        <span className="sec_border"/>
                        <p className="f_16">We are the best option to develop and communicate your investment
                            thesis, as
                            no other consulting firm in the industry has the same comprehensive understanding of the
                            Mexican culture
                            and of the country’s macroeconomic fundamentals than us. How to explain the key operational
                            aspects of your company when the adequate market understanding is lacking? Who is better
                            suited to help you engage with the Mexican market than a capable team formed by Mexican
                            experts and consultants? “We work incessantly inwards to forge a strong international reach
                            outward”.</p>
                    </div>
                </div>
            </section>

            <section className="position-relative">
                <div className="container">
                    <div id="testimonial" className="testimonial_slider owl-carousel testimonial_porque">
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left">IRStrat is the best option for the Mexican market
                                                in Investor Relations, thanks
                                                to
                                                the deep financial
                                                knowledge of its team, its ample portfolio of services and its extensive
                                                network
                                                of
                                                institutional
                                                contacts, both in Mexico and in international markets.</p>
                                        </div>
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={img1} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left">Our expertise in Mexico allow us to work
                                                efficiently to enhance your company’s
                                                access
                                                to the growing
                                                participation of Mexican pension funds in local instruments similar to
                                                yours.</p>
                                        </div>
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={img2} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left">We are in constant communication with the market
                                                and local authorities (BMV,
                                                CNBV, CONSAR, among others), always
                                                vigilant to comply
                                                with regulations and best corporate governance practices and, through
                                                our
                                                Canadian
                                                office, we maintain
                                                close relations with major markets and authorities in North America
                                                (NYSE, TSX,
                                                SEC,
                                                among others).</p>
                                        </div>
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={img3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-5 pb-5 porque">
                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-sm-12">
                            <div className="about_title text-center">
                                <img className="wow fadeInUp w-100" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                     src={soluciones} alt={" "}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
